import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import FindInPageIcon from '@mui/icons-material/FindInPage';

import { Bar, BarChart, XAxis } from "recharts";

import {
    Box,
    Button,
    Table, TableBody, TableCell, TableContainer, TableHead,
    TablePagination,
    TableRow,
    TextField,
    useTheme
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, YAxis } from "recharts";
import 'rsuite/DateRangePicker/styles/index.css';
import Header from '../../components/Header';
import { useCookiesLogin } from '../../config/cookies';
import { HOST_SINGED } from '../../config/hosts';
import { tokens } from '../../theme';

export const description = "A multiple bar chart"

const ConsultList = () => {
    const [filteredRows, setFilteredRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState();
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [dateRange, setDateRange] = useState([null, null]);
    const [documentFilter, setDocumentFilter] = useState('');
    const [showDateRangePicker, setShowDateRangePicker] = useState(false);
    const [showDocumentFilter, setShowDocumentFilter] = useState(false);

    const [consults, setConsults] = useState([]);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { cookies } = useCookiesLogin();

    const { bussines_id } = cookies.BRAUM_TOKEN;

    useEffect(() => {
        console.info("Atualizando filtro datas")
    }, [filteredRows])

    useEffect(() => {
        fetch(`${HOST_SINGED}/reduto/consult/business/${bussines_id}`, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    return res.json();
                }
            })
            .then((data) => {
                console.log(data, "data")
                setConsults(data.consults)
                setFilteredRows(data.consults)
            });
    }, []);

    const handleQuickSelect = (days) => {
        clearFilters();
        const today = dayjs();
        setDateRange([today.subtract(days, 'day'), today]);
        console.info(dateRange);
        setShowDateRangePicker(false);
        filterByDateAndDocument();
    };

    const handleShowDateRangePicker = () => {
        setShowDateRangePicker(!showDateRangePicker);
    };

    const handleShowDocumentFilter = () => {
        setShowDocumentFilter(!showDocumentFilter);
    };

    const filterByDateAndDocument = () => {
        let filtered = consults;

        if (dateRange[0] && dateRange[1]) {
            const startDate = dayjs(dateRange[0]);
            const endDate = dayjs(dateRange[1]);
            filtered = filtered.filter(row => {
                const rowDate = dayjs(row.createdAt);
                return rowDate.isAfter(startDate) && rowDate.isBefore(endDate);
            });
        }

        if (documentFilter) {
            filtered = filtered.filter(row => row.document.includes(documentFilter));
        }

        setFilteredRows(filtered);
    };

    const clearFilters = () => {
        setDateRange([null, null]);
        setDocumentFilter('');
        setFilteredRows(consults);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <Box p={10} m={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Header
                        title={`Todas consultas`}
                        subtitle="Visualize todas as consultas realizadas."
                    />

                    <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>

                        <Button onClick={() => handleQuickSelect(1)} style={{ color: "#c5c5c5" }}>
                            <CalendarTodayIcon sx={{ color: colors.greenAccent[400], marginRight: 1 }} />
                            1 dia
                        </Button>
                        <Button onClick={() => handleQuickSelect(7)} style={{ color: "#c5c5c5" }}>
                            <CalendarTodayIcon sx={{ color: colors.greenAccent[400], marginRight: 1 }} />
                            7 dias
                        </Button>
                        <Button onClick={() => handleQuickSelect(30)} style={{ color: "#c5c5c5" }}>
                            <CalendarTodayIcon sx={{ color: colors.greenAccent[400], marginRight: 1 }} />
                            1 Mês
                        </Button>
                        {/* <Button onClick={handleShowDateRangePicker} style={{ color: "#c5c5c5" }}>
                            <SettingsIcon sx={{ color: colors.greenAccent[400], marginRight: 1 }} />
                            Datas
                        </Button> */}
                        <Button onClick={handleShowDocumentFilter} style={{ color: "#c5c5c5" }}>
                            <FindInPageIcon sx={{ color: colors.greenAccent[400], marginRight: 1 }} />
                            CPF / CNPJ
                        </Button>
                    </Box>

                    <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
                        {
                            showDocumentFilter && <TextField
                                label="CPF/CNPJ"
                                variant="outlined"
                                value={documentFilter}
                                onChange={(e) => {
                                    setDocumentFilter(e.target.value);
                                    filterByDateAndDocument(); // Atualiza a filtragem imediatamente ao digitar
                                }}
                                style={{ marginBottom: '10px', marginRight: '10px' }}
                            />
                        }
                    </Box>


                    {/* {showDateRangePicker && (
                        <Box mt={2}>
                            <DateRangePicker
                                placeholder="Selecione a data"
                                onChange={(range) => {
                                    setDateRange(range);
                                    filterByDateAndDocument(); // Chama a filtragem diretamente
                                }}
                            />
                        </Box>
                    )} */}

                    <Button onClick={clearFilters} variant="contained">Limpar Filtros</Button>
                </LocalizationProvider>

                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>User ID</TableCell>
                                <TableCell>Documento</TableCell>
                                <TableCell>Data de Criação</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (

                                <TableRow key={row.id}>
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell>{row.user_id}</TableCell>
                                    <TableCell>{row.document}</TableCell>
                                    <TableCell>{dayjs(row.created_at).format('YYYY-MM-DD HH:mm')}</TableCell>
                                </TableRow>

                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    component="div"
                    count={filteredRows.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
            <Box>
                {/* <Dashboard></Dashboard> */}
            </Box>
        </>
    );
};

const riskData = [
    { name: "Muito Baixo", value: 30 },
    { name: "Baixíssimo Risco", value: 60 },
    { name: "Baixo", value: 10 },
];

const COLORS = ["#50C878", "#38A169", "#22543D"];

function RiskDistributionChart() {
    return (
        <div className="p-4 shadow-lg rounded-lg">
            <h2 className="text-xl font-semibold mb-4 text-emerald-400">
                Distribuição de Risco de Crédito
            </h2>
            <PieChart width={400} height={300}>
                <Pie
                    data={riskData}
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    dataKey="value"
                    nameKey="name"
                >
                    {riskData.map((_, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip wrapperStyle={{ color: "#50C878" }} />
                <Legend wrapperStyle={{ color: "#A0AEC0" }} />
            </PieChart>
        </div>
    );
}



const activityData = [
    { name: "Criação de bovinos", value: 40 },
    { name: "Cultivo de milho", value: 25 },
    { name: "Cultivo de soja", value: 20 },
    { name: "Outros", value: 15 },
];

function ActivityDistributionChart() {
    return (
        <div className="p-4  shadow-lg rounded-lg">
            <h2 className="text-xl font-semibold mb-4 text-emerald-400">
                Distribuição por Atividade Econômica
            </h2>
            <ResponsiveContainer width="100%" height={300}>
                <BarChart data={activityData}>
                    <XAxis dataKey="name" stroke="#A0AEC0" />
                    <YAxis stroke="#A0AEC0" />
                    <Tooltip wrapperStyle={{ color: "#50C878" }} />
                    <Legend wrapperStyle={{ color: "#A0AEC0" }} />
                    <Bar dataKey="value" fill="#50C878" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}



const locationData = [
    { name: "SP", value: 20 },
    { name: "MG", value: 15 },
    { name: "PR", value: 25 },
    { name: "MS", value: 10 },
    { name: "GO", value: 30 },
];

function LocationDistributionChart() {
    return (
        <div className="p-4 shadow-lg rounded-lg">
            <h2 className="text-xl font-semibold mb-4 text-emerald-400">Distribuição Geográfica</h2>
            <ResponsiveContainer width="100%" height={300}>
                <BarChart data={locationData}>
                    <XAxis dataKey="name" stroke="#A0AEC0" />
                    <YAxis stroke="#A0AEC0" />
                    <Tooltip wrapperStyle={{ color: "#50C878" }} />
                    <Legend wrapperStyle={{ color: "#A0AEC0" }} />
                    <Bar dataKey="value" fill="#38A169" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}

const companySizeData = [
    { name: "MICRO", value: 50 },
    { name: "PEQUENA", value: 35 },
    { name: "MÉDIA", value: 10 },
    { name: "GRANDE", value: 5 },
];

function CompanySizeChart() {
    return (
        <div className="p-4 shadow-lg rounded-lg">
            <h2 className="text-xl font-semibold mb-4 text-emerald-400">
                Perfil de Tamanho da Empresa
            </h2>
            <ResponsiveContainer width="100%" height={300}>
                <BarChart data={companySizeData}>
                    <XAxis dataKey="name" stroke="#A0AEC0" />
                    <YAxis stroke="#A0AEC0" />
                    <Tooltip wrapperStyle={{ color: "#50C878" }} />
                    <Legend wrapperStyle={{ color: "#A0AEC0" }} />
                    <Bar dataKey="value" fill="#22543D" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}


const personTypeData = [
    { name: "Pessoa Física", value: 70 },
    { name: "Pessoa Jurídica", value: 30 },
];

function PersonTypeChart() {
    return (
        <div className="p-4 shadow-lg rounded-lg">
            <h2 className="text-xl font-semibold mb-4 text-emerald-400">
                Distribuição por Pessoa Jurídica/Física
            </h2>
            <PieChart width={400} height={300}>
                <Pie
                    data={personTypeData}
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    dataKey="value"
                    nameKey="name"
                >
                    {personTypeData.map((_, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip wrapperStyle={{ color: "#50C878" }} />
                <Legend wrapperStyle={{ color: "#A0AEC0" }} />
            </PieChart>
        </div>
    );
}


const chartData = [
    { month: "January", desktop: 186, mobile: 80 },
    { month: "February", desktop: 305, mobile: 200 },
    { month: "March", desktop: 237, mobile: 120 },
    { month: "April", desktop: 73, mobile: 190 },
    { month: "May", desktop: 209, mobile: 130 },
    { month: "June", desktop: 214, mobile: 140 },
]

function Dashboard() {
    return (
        <div className="container mx-auto p-8 grid grid-cols-2 gap-4">
            <RiskDistributionChart />
            <ActivityDistributionChart />
            <LocationDistributionChart />
            <CompanySizeChart />
            <PersonTypeChart />
        </div>
    );
}




export default ConsultList;
